$(document).ready(function() {

    $(".video").fitVids();

    $('.flexslider').flexslider({
        animation: "slide",
        keyboard: true,
        directionNav: false
        });
        responsiveNav(".nav-collapse", {
            animate: true,
            transition: 200,
            insert: "before",
        });
    });


    $(function(){
        $('#form__database').submit( function(ev) {
        ev.preventDefault();
        $( '.errors' ).remove();
        var dataToSend = $(this).serialize();
        var data = $(this).serialize();
        $.post('/', data, {},'json')
        .done(function(response) {
            $('#form__database')[0].reset();
            $('#thanks').fadeIn();
          })
          .fail(function(error) {

            for ( var fieldId in error.responseJSON.errors ) {
                var $errors = $( '<ul class="errors" />' );
                var messages = $.map(error.responseJSON.errors[fieldId], function(message) {
                return '<li>' + message + '</li>';
                });
                $errors.append( messages.join( '' ) );
                $('#form__database').after($errors);
            }

          });
    });

    lightbox.option({
        'disableScrolling': true,
        'fadeDuration': 400,
        'imageFadeDuration': 400,
        'resizeDuration': 600,

      })


});





// import "../old/responsive-nav";
// import "../old/jquery.flexslider";
// import "../old/lightbox";
// import "../old/jquery.fitvids";

// import { createApp } from 'vue';

// createApp()
//     .mount('#app');
